@import './variables/colors';
@import './variables/fonts';

#highlightedProject {
    margin-top: 10px;
    padding: 10px 0 10px 0;
    width: 100%;
    border: 1px solid black;
    background-color: $light-background-accent;
    font-family: $font-roboto;
    box-shadow: 0 0 30px 0px black;
    text-align: center;
    
    #closeButton {
        float: right;
    }
    
    #projectInfo {
        text-align: center;
        color: #DDD;
        h2 {
            color: #FFF;
        }
    }

    #imageContainer {
        text-align: center;
        img {
            width: 60vw;
            max-width: 600px;
            height: auto;
        }
    }

    #projectDesc {
        margin-top: 2vh;
        text-align: center;
        display: inline-block;
        width: 80%;
        max-width: 900px;
        color: #BBB;
        a {
            text-decoration: underline;
            color: #BBB;
        }
    }
}