@import "./variables/sizes";
@import "./variables/fonts";

.header {
    .info {
        h1, h2, p {
            font-family: $font-roboto;
            position: absolute;
            left: 50%;
            z-index: 2;
            pointer-events: none;
            
            color: black;
            -webkit-text-fill-color: white; /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: black;
        }

        h1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 5em;
            transform: translate(-50%, 1.5em);

            @media only screen and (max-width: 500px) {
                font-size: 15vw;
            }
        }
    
        h2 {
            font-size: 2em;
            transform: translate(-50%, 7em);
            
            @media only screen and (max-width: 500px) {
                font-size: 8vw;
                transform: translate(-50%, 5em);
            }
        }
    }
    .changeBtn {
        position: absolute;
        z-index: 3;
    }
    .canvasView {
      
    }
}
