@import './variables/colors';
@import './variables/fonts';

.project {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 30px 0px black;

    @media only screen and (max-width: 575px) {
        border: 2px solid #555;
    }
    
    #imageContainer {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; 

        .innerContent {
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.85);
            width: 100%;
            height: 100%;
            color: $offwhite;

            text-align: center;
            font-family: $font-roboto;

            @media only screen and (min-width: 1200px) {
                padding: 7vh 0 7vh 0;
            }

            @media only screen and (min-width: 770px) and (max-width: 1200px) {
                padding: 3vh 0 3vh 0;
            }

            @media only screen and (min-width: 488px) and (max-width: 575px) {
                padding: 5vh 0 5vh 0;
            }

            @media only screen and (max-width: 488px) {
                padding: 3vh 0 3vh 0;
            }

            h2 {
                font-weight: bold;
                font-size: 2.2rem;
                font-size: clamp(1.25rem, calc(5vw + 1rem), 2.2rem);
                line-height: 1;
                text-transform: uppercase;
            }
            p {
                font-size: 1.2rem;
            }
        }

        .innerContent-anim {
            animation: fadeOut ease 1s;
            -webkit-animation: fadeOut ease 1s;
            -moz-animation: fadeOut ease 1s;
            -o-animation: fadeOut ease 1s;
            -ms-animation: fadeOut ease 1s;
        }

        .mobileSelected {
            animation: fadeIn ease 1s;
            -webkit-animation: fadeIn ease 1s;
            -moz-animation: fadeIn ease 1s;
            -o-animation: fadeIn ease 1s;
            -ms-animation: fadeIn ease 1s;
            animation-fill-mode: forwards;
        }
    }
}

.project:hover, .project:focus, .project:active {
    cursor:pointer;
    #imageContainer {
        .innerContent {
            animation: fadeIn ease 1s;
            -webkit-animation: fadeIn ease 1s;
            -moz-animation: fadeIn ease 1s;
            -o-animation: fadeIn ease 1s;
            -ms-animation: fadeIn ease 1s;
            animation-fill-mode: forwards;
        }
    }
}


/////////////////////////////////////////////////////////////////
// ANIMATIONS


@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}