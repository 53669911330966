@media (min-width: 1200px) {
    .container{
        max-width: 1600px;
    }
}

.rowOfProjects {
    @media only screen and (max-width: 575px) {
        margin-top: 30px !important;
    }
}