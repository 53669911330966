@import './variables/colors';
@import './variables/fonts';

.social-media {
    background-color: $light-background-accent;
}

.social-media-link {
    height: 64px;
    width: 64px;
    @media only screen and (max-width: 350px) {
        height: 48px;
        width: 48px;
    }
}

.credit {
    padding-top: 10px;
    font-family: $font-roboto;
    a {
        color: white;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}